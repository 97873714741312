import Hoodie from "@/assets/images/3d/Hoodie.png";
import Layer_0 from "@/assets/images/3d/Layer_0.png";
// import PF01 from '@/assets/images/3d/PF01.png'
import PaddingVest from "@/assets/images/3d/PaddingVest.png";
import Track_Suit from "@/assets/images/3d/Track_Suit.png";
import Leggings from "@/assets/images/3d/Leggings.png";
// import Tuxedo_Pants from '@/assets/images/3d/Tuxedo_Pants.png'
// import JACKET_TROUSER from '@/assets/images/3d/JACKET_TROUSER.png'
// import COWL_NECK_TROUSER from '@/assets/images/3d/COWL_NECK_TROUSER.png'

export interface Fabric3dModeItem {
  is3d: boolean;
  id: number;
  image_url: string;
  name: string;
  key: string;
  render_parts: string[];
  tg3d_fabric_id?: string;
  tg3d_fabric_scan_record?: string;
  tg3d_fabric_view_url?: string;
}

export interface FabricIframeModeItem {
  id: number;
  file: string;
  isIframe: boolean;
}

export const fabric3dModeItems: Fabric3dModeItem[] = [
  {
    is3d: true,
    id: 1000,
    image_url: Hoodie,
    name: "Hoodie",
    key: "02wOBG2j",
    render_parts: ["Primary"]
  },
  {
    is3d: true,
    id: 1001,
    image_url: Layer_0,
    name: "T-Shirt",
    key: "pk447Rk8",
    render_parts: ["Primary"]
  },
  // {
  //   id: 2,
  //   image_url: PF01,
  //   name: 'PF01',
  //   key: 'xkKOAdk9',
  //   render_parts: ['Primary','Back','Collar','Cuff','Front','Pocket']
  // },
  {
    is3d: true,
    id: 1003,
    image_url: PaddingVest,
    name: "PaddingVest",
    key: "pzVwMb2A",
    render_parts: ["Primary"]
  },
  {
    is3d: true,
    id: 1004,
    image_url: Track_Suit,
    name: "Track Suit",
    key: "5PqXBa2X",
    render_parts: [
      "Primary",
      "Dark",
      "Frontmix",
      "leftupperfront",
      "rightupperfront",
      "Orange"
    ]
  },
  {
    is3d: true,
    id: 1005,
    image_url: Leggings,
    name: "Leggings",
    key: "LPvq612M",
    render_parts: ["Primary", "Front", "Back", "Side"]
  }
  // {
  //   id: 6,
  //   image_url: Tuxedo_Pants,
  //   name: 'Tuxedo Pants',
  //   key: 'lz11dwza',
  //   render_parts: ['Primary','Cuff', 'Front', 'Side-seam Stripes','Waistband']
  // },
  // {
  //   id: 7,
  //   image_url: JACKET_TROUSER,
  //   name: 'JACKET + TROUSER',
  //   key: 'EPYpBe2q',
  //   render_parts: ['Primary','JACKET','TROUSER']
  // },
  // {
  //   id: 8,
  //   image_url: COWL_NECK_TROUSER,
  //   name: 'COWL NECK + TROUSER',
  //   key: 'XzRoBW2e',
  //   render_parts: ['Primary','TOP','TROUSER']
  // }
];
